// src/MenuItemCard.js
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function MenuItemCard({ item }) {
  return (
    <Card>
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" component="div">
            {item.name}
          </Typography>
          <Typography variant="body1" component="div" style={{ fontWeight: 'bold' }}>
            {item.price}
          </Typography>
        </div>
        <Typography variant="body2" color="textSecondary">
          {item.description}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default MenuItemCard;

