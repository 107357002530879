// src/gristService.js
import axios from 'axios';

const GRIST_API_TOKEN = process.env.REACT_APP_GRIST_API_TOKEN;
const DOCUMENT_ID = 'wWCo7CH3bydq';  // Your Grist document ID
const MENU_ITEMS_TABLE = 'Menu_Items';  // Your Grist menu items table name
const MENU_GROUPS_TABLE = 'Menu_Groups';  // Your Grist menu groups table name

// Function to fetch data from Netlify in production
const fetchFromNetlify = async (functionName) => {
  try {
    const response = await axios.get(`/.netlify/functions/${functionName}`);
    return response;
  } catch (error) {
    console.error(`Error fetching data from ${functionName}:`, error);
    return [];
  }
};

// Function to fetch menu items
const getMenuItems = async () => {
  if (process.env.NODE_ENV === 'production') {
    return await fetchFromNetlify('getMenuItems');
  }
  return axios.get(`/api/docs/${DOCUMENT_ID}/tables/${MENU_ITEMS_TABLE}/records`, {
    headers: {
      Authorization: `Bearer ${GRIST_API_TOKEN}`,
    },
    params: {
      filter: JSON.stringify({ eggcellent: [true] })
    }
  });
};

// Function to fetch menu groups
const getMenuGroups = async () => {
  if (process.env.NODE_ENV === 'production') {
    return await fetchFromNetlify('getMenuGroups');
  }
  return axios.get(`/api/docs/${DOCUMENT_ID}/tables/${MENU_GROUPS_TABLE}/records`, {
    headers: {
      Authorization: `Bearer ${GRIST_API_TOKEN}`,
    }
  });
};

export const fetchMenuData = async () => {
  try {
    // Fetch both menu items and menu groups concurrently
    const [menuItemsResponse, menuGroupsResponse] = await Promise.all([
      getMenuItems(),
      getMenuGroups()
    ]);

    // Map the groups to an object for easier access, including category assignment
    const groups = menuGroupsResponse.data.records.reduce((acc, record) => {
      const categories = record.fields.Categories;
      const category = categories && categories.includes('Food') ? 'Food' :
                       categories && categories.includes('Beverage') ? 'Beverage' : 'Uncategorized';
      acc[record.id] = { name: record.fields.Name, category: category };
      return acc;
    }, {});

    // Combine menu items with their group names and categorize them as Food or Beverage
    const combinedData = menuItemsResponse.data.records
      .filter(record => record.fields.Status === 'active')  // Filter by 'active' status
      .reduce((acc, record) => {
        const group = groups[record.fields.Group ? record.fields.Group[1] : null];
        const category = group ? group.category : 'Uncategorized';

        // Ignore item if group.name is missing
        if (!group || !group.name) {
          return acc;
        }

        if (!acc[category]) {
          acc[category] = {};
        }
        if (!acc[category][group.name]) {
          acc[category][group.name] = [];
        }
        acc[category][group.name].push({
          id: record.id,
          name: record.fields.Name,
          description: record.fields.Description || '',
          price: record.fields.Price ? `$${record.fields.Price}` : '',  // Set price to an empty string if null or blank
        });
        return acc;
      }, {});

    return combinedData;
  } catch (error) {
    console.error('Error fetching menu data from Grist:', error);
    return {};
  }
};

