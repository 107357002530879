// src/MenuList.js
import React, { useEffect, useState } from 'react';
import { Typography, Grid, Divider, Tabs, Tab, Box } from '@mui/material';
import { fetchMenuData } from './gristService';
import MenuItemCard from './MenuItemCard';

function MenuList() {
  const [menuData, setMenuData] = useState({});
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);  // State to handle active tab

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMenuData();
        setMenuData(data || {});
      } catch (error) {
        console.error('Error fetching menu data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  const categories = Object.keys(menuData).sort((a, b) => {
    if (a === 'Food') return -1;
    if (b === 'Food') return 1;
    if (a === 'Beverage') return -1;
    if (b === 'Beverage') return 1;
    return 0;
  });


  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Eggcellent Cafe
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="menu categories">
        {categories.map((category, index) => (
          <Tab key={index} label={category} />
        ))}
      </Tabs>

      {categories.map((category, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          {Object.keys(menuData[category]).map((group) => (
            <div key={group}>
              <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
                {group}
              </Typography>
              <Divider style={{ marginBottom: '15px' }} />
              <Grid container spacing={3}>
                {menuData[category][group].map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <MenuItemCard item={item} />
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </TabPanel>
      ))}
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default MenuList;

